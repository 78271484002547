import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { history } from "./_helpers";
import ReactGA from "react-ga";

import ProfilePage from "./pages/ProfilePage";
import CaseStudiesPage from "./pages/CaseStudiesPage";
import ContactPage from "./pages/ContactPage";
import PrivacyTermsPage from "./pages/PrivacyTermsPage";
import CaseStudyCarInsurance from "./pages/CaseStudyCarInsurance";
import CaseStudyAgeVisibility from "./pages/CaseStudyAgeVisibility";
import CaseStudyGDPR from "./pages/CaseStudyGDPR";
import NotFound from "./pages/NotFound";
import CaseStudyImprovingFeatureAdoption from "./pages/CaseStudyImprovingFeatureAdoption";

import { hotjar } from "react-hotjar";

hotjar.initialize(278573, 6);

const routes = [
  { path: "/", name: "Profile", Component: ProfilePage },
  { path: "/case-studies/", name: "Case Studies", Component: CaseStudiesPage },
  { path: "/contact/", name: "Contact", Component: ContactPage },
  {
    path: "/case-studies/car-insurance/",
    name: "Car Insurance",
    Component: CaseStudyCarInsurance,
  },
  {
    path: "/case-studies/improving-feature-adoption/",
    name: "Improving Feature Adoption",
    Component: CaseStudyImprovingFeatureAdoption,
  },

  {
    path: "/case-studies/age-visibility/",
    name: "Age Visibility",
    Component: CaseStudyAgeVisibility,
  },
  {
    path: "/case-studies/gdpr-marketing-consent/",
    name: "GDPR Marketing Consent",
    Component: CaseStudyGDPR,
  },
  // {
  //   path: "/case-studies/building-a-design-system/",
  //   name: "Building a Design System",
  //   Component: CaseStudyDesignSystem
  // },
  {
    path: "/privacy-and-terms",
    name: "Privacy + Terms",
    Component: PrivacyTermsPage,
  },
];

export default class Routes extends Component {
  componentWillMount() {
    ReactGA.initialize("UA-35604204-1", { debug: true });
    // ReactGA.set({ page: history.location.pathname + history.location.search });
    // ReactGA.pageview(history.location.pathname + history.location.search);
  }

  render() {
    const supportsHistory = "pushState" in window.history;

    return (
      <BrowserRouter history={history} forceRefresh={!supportsHistory}>
        {/* <ScrollToTop> */}
        <Switch>
          {routes.map(({ path, Component }) => (
            <Route key={path} exact path={path}>
              {({ match }) => (
                // <CSSTransition in={match != null} timeout={1500} classNames="page" unmountOnExit>
                <Component />
                // </CSSTransition>
              )}
            </Route>
          ))}
          <Route>
            <NotFound />
          </Route>
        </Switch>
        {/* <Route exact path="/" component={ProfilePage} />
            <Route exact path="/case-studies/" component={CaseStudiesPage} />
            <Route path="/contact/" component={ContactPage} />
            <Route path="/case-studies/car-insurance" component={CaseStudyCarInsurance} /> */}
        {/* </ScrollToTop> */}
      </BrowserRouter>
    );
  }
}
