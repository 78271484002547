import React, { Component } from "react";
import { css } from "aphrodite";
import { styles } from "./FooterStyles";

import Logo from "./Logo";
import Link from "./Link";

export default class Footer extends Component {
  render() {
    return (
      <footer className={css(styles.footerWrapper)}>
        <div className={css(styles.footer)}>
          <div className={css(styles.footerLeading)}>
            <ul className={css([styles.footerList, styles.footerLeadingItem])}>
              <li className={css([styles.footerListItem, styles.logoWrapper])}>
                <Logo type="white" />
              </li>
              <li className={css(styles.footerListItem)}>
                <strong className={css(styles.name)}>Luke James Taylor</strong>
              </li>
              <li className={css(styles.footerListItem)}>
                <p className={css(styles.jobTitle)}>Design Consultant</p>
              </li>
            </ul>
            <ul className={css([styles.footerList, styles.footerLeadingItem])}>
              <li className={css(styles.footerListItem)}>
                <Link href="/">Profile</Link>
              </li>
              <li className={css(styles.footerListItem)}>
                <Link href="/case-studies/">Case Studies</Link>
              </li>
              <li className={css(styles.footerListItem)}>
                <Link href="/contact/">Contact</Link>
              </li>
            </ul>
            <ul className={css([styles.footerList, styles.footerLeadingItem])}>
              {/* <li className={css(styles.footerListItem)}>
                <Link
                  href="https://twitter.com/lukejamestaylor"
                  rel="norel"
                  target="_blank"
                >
                  X
                </Link>
              </li> */}
              <li className={css(styles.footerListItem)}>
                <Link
                  href="https://www.linkedin.com/in/lukejamestaylor/"
                  rel="norel"
                  target="_blank"
                >
                  LinkedIn
                </Link>
              </li>
              <li className={css(styles.footerListItem)}>
                <Link
                  href="https://designsprintx.com"
                  rel="norel"
                  target="_blank"
                >
                  Design Sprint X
                </Link>
              </li>
            </ul>
          </div>
          <ul className={css([styles.footerTrailing, styles.footerList])}>
            <li className={css(styles.footerListItem)}>
              <Link
                href="mailto:lukejamestaylor@me.com"
                rel="norel"
                target="_blank"
              >
                lukejamestaylor@me.com
              </Link>
            </li>
            <li className={css(styles.footerListItem)}>
              <Link href="/privacy-and-terms/" rel="norel" target="_blank">
                Privacy + Terms
              </Link>
            </li>
            <li className={css(styles.footerListItem)}>
              <span>&copy; {new Date().getFullYear()} Design Sprint Ltd.</span>
            </li>
          </ul>
        </div>
      </footer>
    );
  }
}
