import React, { Component } from "react";
import { css } from "aphrodite";
import { styles } from "./LogoStyles";

export default class Logo extends Component {
  render() {
    let logoFilename = "logo.svg";

    if (this.props.type === "white") {
      logoFilename = "logo-white.svg";
    }

    return (
      <a href="/" className={css(styles.logo)}>
        <img
          className={css(styles.logoImage)}
          src={`/images/${logoFilename}`}
          alt="Luke James Taylor Logo"
        />
      </a>
    );
  }
}
