import React, { Component } from "react";
import { css } from "aphrodite";
import { styles } from "./LogoListStyles";
import Title500 from "./Title500";
import Badge from "../components/Badge";

export default class Section extends Component {
  render() {
    return (
      <ul className={css(styles.logoList)}>
        <li className={css(styles.logoListWrapper)}>
          <a
            href="https://www.designsprintx.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={css(styles.logoListItem)}>
              <img
                src="/images/logos/design-sprint-x.svg"
                alt="Design Sprint X logo"
                className={css(styles.logoListItemImage)}
              />
            </div>
          </a>
          <Title500>Design sprint facilitation and training.</Title500>
          <Badge>New Website</Badge>
        </li>
        <li className={css(styles.logoListWrapper)}>
          <a
            href="https://www.theotherhand.co.uk"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={css(styles.logoListItem)}>
              <img
                src="/images/logos/the-other-hand.svg"
                alt="The Other Hand logo"
                className={css(styles.logoListItemImage)}
              />
            </div>
          </a>
          <Title500>Branding, identity, packaging, and illustration.</Title500>
        </li>
        <li className={css(styles.logoListWrapper)}>
          <a
            href="https://spacesprint.co.uk"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={css(styles.logoListItem)}>
              <img
                src="/images/logos/space-sprint.svg"
                alt="Space Sprint logo"
                className={css(styles.logoListItemImage)}
              />
            </div>
          </a>
          <Title500>Digital product design and development.</Title500>
        </li>
      </ul>
    );
  }
}
