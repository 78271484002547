import React, { Component } from "react";
import { css } from "aphrodite";
import { styles } from "./ServicesDetailStyles";
import { globalStyles } from "./GlobalStyles";

import Title400 from "./Title400";
import Paragraph from "./Paragraph";
import Link from "./Link";

export default class ServicesDetail extends Component {
  render() {
    return (
      <ul className={css(styles.servicesDetailList)}>
        <li className={css(styles.servicesDetailListItem)}>
          <img
            className={css(styles.servicesDetailIcon)}
            src={"/images/icons/services-design-strategy.svg"}
            alt="Icon depiciting design strategy"
          />
          <Title400>
            <h3>Design Strategy</h3>
          </Title400>
          <Paragraph>
            Movement does not mean progress. It’s just as important to focus on
            the medium and long term as it is to have a well-designed product.
          </Paragraph>
        </li>
        <li className={css(styles.servicesDetailListItem)}>
          <img
            className={css(styles.servicesDetailIcon)}
            src={"/images/icons/services-design-sprint.svg"}
            alt="Icon depiciting design strategy"
          />
          <Title400>
            <h3>Design Sprints</h3>
          </Title400>
          <Paragraph>
            Rapidly save time, money and reduce risk. I co-founded the dedicated
            Design Sprint company{" "}
            <span className={css(globalStyles.inlineBlock)}>
              <Link href="https://designsprintx.com">Design Sprint X</Link>
            </span>
            .
          </Paragraph>
        </li>
        <li className={css(styles.servicesDetailListItem)}>
          <img
            className={css(styles.servicesDetailIcon)}
            src={"/images/icons/services-design-system.svg"}
            alt="Icon depiciting design strategy"
          />
          <Title400>
            <h3>Design Systems</h3>
          </Title400>
          <Paragraph>
            Efficiency is the key to a well-functioning design team. I’ve worked
            on early Design Systems at{" "}
            <span className={css(globalStyles.inlineBlock)}>
              <Link href="https://db.com">Deutsche Bank</Link>
            </span>
            ,{" "}
            <span className={css(globalStyles.inlineBlock)}>
              <Link href="https://bp.com">bp</Link>
            </span>
            ,{" "}
            <span className={css(globalStyles.inlineBlock)}>
              <Link href="https://britishgas.co.uk">British Gas</Link>.
            </span>{" "}
            and <Link href="https://skybet.com">Sky Betting &amp; Gaming</Link>.
          </Paragraph>
        </li>
        <li className={css(styles.servicesDetailListItem)}>
          <img
            className={css(styles.servicesDetailIcon)}
            src={"/images/icons/services-web-and-mobile-design.svg"}
            alt="Icon depiciting design strategy"
          />
          <Title400>
            <h3>Web and Mobile App Design</h3>
          </Title400>
          <Paragraph>
            I'm the co-founder of{" "}
            <Link href="http://spacesprint.co.uk" target="_blank">
              Space Sprint
            </Link>{" "}
            , where we are passionate about bringing your ideas to life.
          </Paragraph>
        </li>
        <li className={css(styles.servicesDetailListItem)}>
          <img
            className={css(styles.servicesDetailIcon)}
            src={"/images/icons/services-development.svg"}
            alt="Icon depiciting design strategy"
          />
          {/* <Title400>
            <h3>Front-end Development</h3>
          </Title400>
          <Paragraph>
            Being able to build the designs I produce means I can bring your
            idea to life. It also means I don't design anything that can't
            technically be created.
          </Paragraph> */}
          <Title400>
            <h3>Branding, Identity, Packaging, and Illustration.</h3>
          </Title400>
          <Paragraph>
            Working with a talented designer at{" "}
            <Link href="http://theotherhand.co.uk" target="_blank">
              The Other Hand
            </Link>
            .
          </Paragraph>
        </li>
        <li className={css(styles.servicesDetailListItem)}>
          <img
            className={css(styles.servicesDetailIcon)}
            src={"/images/icons/services-workshop.svg"}
            alt="Icon depiciting design strategy"
          />
          <Title400>
            <h3>Workshop Facilitation</h3>
          </Title400>
          <Paragraph>
            When a Design Sprint isn’t suitable, sometimes a workshop will
            suffice. I’ve run workshops for many clients from start-ups to
            FTSE100s.
          </Paragraph>
        </li>
      </ul>
    );
  }
}
