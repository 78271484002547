import React, { Component } from "react";
import { css } from "aphrodite";
import ReactGA from "react-ga";

import Header from "../components/Header";
import Hero from "../components/Hero";
import Title from "../components/Title";
import Link from "../components/Link";
import Subtitle from "../components/Subtitle";
import Section from "../components/Section";
import Footer from "../components/Footer";

import Title400 from "../components/Title400";
import Title500 from "../components/Title500";
import Paragraph from "../components/Paragraph";
import CaseStudyCardWide from "../components/CaseStudyCardWide";
import { globalStyles } from "../components/GlobalStyles";
import { styles as caseStudyStyles } from "../components/CaseStudyStyles";

export default class CaseStudiesPage extends Component {
  componentDidMount() {
    document.title += " — Case Studies";
  }
  componentWillMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    return (
      <div>
        <Header />
        <Hero
          sequence="&mdash;"
          title="Case Studies. Client outcomes."
          descriptions={[
            "A selection of recent client projects.",
            "Focussing on process and outcome.",
            "Solutions from a range of industries from insurance through to gambling.",
          ]}
          trailingContent={
            <div>
              <Title500>Case Studies:</Title500>
              <ul
                className={css([
                  caseStudyStyles.summaryListItemList,
                  caseStudyStyles.summaryListItemListNoBullet,
                ])}
              >
                <li className={css(caseStudyStyles.summaryListItemListItem)}>
                  01/{" "}
                  <Link href="/case-studies/car-insurance/">
                    Car Insurance Reimagined
                  </Link>
                </li>
                <li className={css(caseStudyStyles.summaryListItemListItem)}>
                  02/{" "}
                  <Link href="/case-studies/age-visibility/">
                    Gambling Age Visibility
                  </Link>
                </li>
                <li className={css(caseStudyStyles.summaryListItemListItem)}>
                  03/{" "}
                  <Link href="/case-studies/gdpr-marketing-consent/">
                    GDPR Marketing Consent
                  </Link>
                </li>
                <li className={css(caseStudyStyles.summaryListItemListItem)}>
                  04/{" "}
                  <Link href="/case-studies/improving-feature-adoption/">
                    <span className={css(globalStyles.marginRight)}>
                      Improving Feature Adoption
                    </span>
                    {/* <Badge size="small">New</Badge> */}
                  </Link>
                </li>
                <li className={css(caseStudyStyles.summaryListItemListItem)}>
                  05/ Automating Journey Maps (Coming Soon)
                </li>
                <li className={css(caseStudyStyles.summaryListItemListItem)}>
                  06/ Information Architecture Overhaul (Coming Soon)
                </li>
              </ul>
            </div>
          }
        />

        <Section>
          <h1>
            <Title sequence={"01/"} title={"Car Insurance Reimagined"} />
          </h1>
          <h2>
            <Subtitle>
              How a startup wanted to disrupt the well-established car insurance
              industry
            </Subtitle>
          </h2>
          <Title400>— Outcome</Title400>
          <div className={css(globalStyles.paddingBottom5x)}>
            <Paragraph>
              Starting from scratch on a well-established industry, we delivered
              a prototype mobile app that stood out from the entire industry.
              The prototype also helped their developers build a
              proof-of-concept iOS app, and to bring the vision to life for
              potential investors.
            </Paragraph>
          </div>
          <CaseStudyCardWide
            role={"Designer"}
            services={
              "Design strategy, mobile app design, workshop facilitation"
            }
            link="/case-studies/car-insurance/"
          />
        </Section>

        <Section background="grey">
          <h1>
            <Title sequence={"02/"} title={"Gambling Age Visibility"} />
          </h1>
          <h2>
            <Subtitle>
              Being responsible, by improving age restriction visibility on a
              gambling product.
            </Subtitle>
          </h2>
          <Title400>— Outcome</Title400>
          <div className={css(globalStyles.paddingBottom5x)}>
            <Paragraph>
              Being asked to add a bigger 18+ badge to the product was the
              starting point. Instead, I looked at how we can prove that users
              could recall the age restriction during registration. 5 designs
              later and 100’s of users tested gave a 78% increase in recall over
              the original live design.
            </Paragraph>
          </div>
          <CaseStudyCardWide
            role={"Designer"}
            services={
              "Research, user testing, optimisation, behavioural insight"
            }
            link="/case-studies/age-visibility/"
          />
        </Section>

        <Section>
          <h1>
            <Title sequence={"03/"} title={"GDPR Marketing Consent"} />
          </h1>
          <h2>
            <Subtitle>
              3 million customers and how to ask them if they’d like to be
              marketed to, again.
            </Subtitle>
          </h2>
          <Title400>— Outcome</Title400>
          <div className={css(globalStyles.paddingBottom5x)}>
            <Paragraph>
              With the looming GDPR compliance deadline approaching, the client
              needed to ask all of their 3 million customers if they'd like to
              be marketed to, again. Careful research, user testing, HTML
              prototypes, and many iterations later gave me the least risky
              solution resulting in only a 1% net loss of consent.
            </Paragraph>
          </div>
          <CaseStudyCardWide
            role={"Designer"}
            services={"Research, GDPR consultation, user testing, marketing"}
            link="/case-studies/gdpr-marketing-consent"
          />
        </Section>

        <Section background="grey">
          <h1>
            <Title sequence={"04/"} title={"Improving Feature Adoption"} />
          </h1>
          <h2>
            <Subtitle>
              Understanding why a new feature had a slow adoption rate and then
              improving it
            </Subtitle>
          </h2>
          <Title400>— Outcome</Title400>
          <div className={css(globalStyles.paddingBottom5x)}>
            <Paragraph>
              Significant increases in visits and interaction with the new
              feature through multiple rounds of split testing.
            </Paragraph>
          </div>
          <CaseStudyCardWide
            role={"Designer"}
            services={"Optimisation, split-testing, research, analytics"}
            link="/case-studies/improving-feature-adoption"
          />
        </Section>

        <Section>
          <h1>
            <Title sequence={"05/"} title={"Automating Journey Maps"} />
          </h1>
          <h2>
            <Subtitle>
              Taking data from Adobe Analytics and populating a Sketch flow
              diagram.
            </Subtitle>
          </h2>
          <Title400>— Outcome</Title400>
          <div className={css(globalStyles.paddingBottom5x)}>
            <Paragraph>
              Taking a manual task from 1 day to 5 minutes per month.
            </Paragraph>
          </div>
          <CaseStudyCardWide
            role={"Developer"}
            services={"Automation, analytics, user flow"}
            linkType="disabled"
          />
        </Section>

        <Section background="grey">
          <h1>
            <Title
              sequence={"06/"}
              title={"Information Architecture Overhaul"}
            />
          </h1>
          <h2>
            <Subtitle>
              A full research-led overhaul of a large information architecture.
            </Subtitle>
          </h2>
          <Title400>— Outcome</Title400>
          <div className={css(globalStyles.paddingBottom5x)}>
            <Paragraph>
              Huge percentage increases in discoverability, findability and
              understanding of the navigational structure.
            </Paragraph>
          </div>
          <CaseStudyCardWide
            role={"Designer"}
            services={"User research, research-led, information architecture"}
            linkType="disabled"
          />
        </Section>

        <Footer />
      </div>
    );
  }
}
