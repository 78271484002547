import React, { Component } from "react";
import { css } from "aphrodite";
import { styles } from "./HeaderStyles";

import Navigation from "./Navigation";
import Logo from "./Logo";

export default class Header extends Component {
  render() {
    return (
      <div>
        {/* <Banner /> */}
        <header className={css(styles.header)}>
          <Logo />
          <Navigation />
          {/* <div className={css(styles.headerTrailing)}>
            <Button
              onClick={() => {
                ReactGA.event({
                  category: "CV",
                  action: "View CV from Header",
                });
              }}
              target="_blank"
              size="small"
              link="/Luke Taylor CV.pdf"
            >
              View CV
            </Button>
          </div> */}
        </header>
      </div>
    );
  }
}
