import React, { Component } from "react";
import { css } from "aphrodite";
import { styles } from "./CommentsDetailStyles";
import Paragraph from "./Paragraph";
import Cite from "./Cite";

export default class IndustriesDetail extends Component {
  render() {
    return (
      <div className={css(styles.quoteWrapper)}>
        <q className={css(styles.quote)}>
          Luke made the user experience, customer journey and graphical design
          processes very straightforward for us, after a detailed face-to-face
          session he was able to deliver wireframes, interactive mockups and
          assets quickly and autonomously. He worked as if he was part of the
          team, treating our problems as his own and helped us come up with
          solutions that would work well for the end-users.
        </q>
        <Cite
          img="/images/paulridgway.jpeg"
          name="Paul Ridgway"
          description={
            <Paragraph size="small">
              Technology Leader &amp; Strategist
            </Paragraph>
          }
        />
      </div>
    );
  }
}
