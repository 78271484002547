import React, { Component } from "react";
import ReactGA from "react-ga";
import { css } from "aphrodite";

import { globalStyles } from "../components/GlobalStyles";

import Header from "../components/Header";
import Hero from "../components/Hero";
import Title from "../components/Title";
import Button from "../components/Button";
import Subtitle from "../components/Subtitle";
import Section from "../components/Section";
import Footer from "../components/Footer";

import LogoList from "../components/LogoList";
import MyCompaniesList from "../components/MyCompaniesList";
import ServicesDetail from "../components/ServicesDetail";
import AgilePhilosophyDetail from "../components/AgilePhilosophyDetail";
import PrinciplesDetail from "../components/PrinciplesDetail";
import IndustriesDetail from "../components/IndustriesDetail";
import CommentsDetail from "../components/CommentsDetail";

export default class ProfilePage extends Component {
  componentWillMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    return (
      <div>
        <Header />
        <div className={css(globalStyles.paddingBottom10x)}>
          <Hero
            sequence="&mdash;"
            title="A designer. Problem solver."
            descriptions={[
              "I'm Luke James Taylor.",
              "A consultant product design leader and co-founder of multiple businesses.",
              "Specialising in design sprints, strategy, improving design maturity & design systems.",
            ]}
            trailingContent={
              <img
                className={css(globalStyles.profileHeadshot)}
                src="/images/luke-james-taylor.jpeg"
                alt="Headshot profile of Luke James Taylor"
              />
              // <CaseStudyCard
              //   // isNew
              //   title={"Highlighted Case Study"}
              //   description={"Improving Feature Adoption  ⟶"}
              //   meta={
              //     <Link styleOnly={true} size={"small"}>
              //       View Case Study
              //     </Link>
              //   }
              //   link={"/case-studies/improving-feature-adoption/"}
              // />
            }
          />
        </div>

        <Section background={"grey"}>
          <h1>
            <Title sequence={"01/"} title={"Co-founder"} />
          </h1>
          <h2>
            <Subtitle>Working with talented co-founders</Subtitle>
          </h2>
          <MyCompaniesList />
        </Section>

        <Section>
          <h1>
            <Title sequence={"02/"} title={"Trusted"} />
          </h1>
          <h2>
            <Subtitle>By some of the world's biggest brands.</Subtitle>
          </h2>
          <LogoList />
        </Section>

        <Section background={"grey"}>
          <h1>
            <Title sequence={"03/"} title={"Services"} />
          </h1>
          <h2>
            <Subtitle>Design, development, and strategy.</Subtitle>
          </h2>
          <ServicesDetail />
        </Section>

        <Section>
          <h1>
            <Title sequence={"04/"} title={"Agile Team Philosophy"} />
          </h1>
          <h2>
            <Subtitle>
              Involve users early and often. Learn from them and iterate.
            </Subtitle>
          </h2>
          <AgilePhilosophyDetail />
        </Section>

        <Section background={"black"}>
          <h1>
            <Title sequence={"05/"} title={"Principles"} type="onBlack" />
          </h1>
          <PrinciplesDetail />
        </Section>

        <Section>
          <h1>
            <Title sequence={"06/"} title={"Industries"} />
          </h1>
          <h2>
            <Subtitle>
              Extensive experience across a diverse range of sectors.
            </Subtitle>
          </h2>
          <IndustriesDetail />
        </Section>

        <Section background={"grey"}>
          <h1>
            <Title
              sequence={"07/"}
              title={"Client Comments"}
              alignment={"centered"}
            />
          </h1>
          <CommentsDetail />
        </Section>

        <Section>
          <h1>
            <Title sequence={"08/"} title={"Case Studies"} alignment={"left"} />
          </h1>
          <h2>
            <Subtitle>
              A selection of recent client projects, focussing on process and
              outcome.
            </Subtitle>
          </h2>
          <Button display="inline-block" size="small" link={"/case-studies/"}>
            View Case Studies
          </Button>
        </Section>

        <Footer />
      </div>
    );
  }
}
