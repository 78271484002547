import React, { Component } from "react";
import { css } from "aphrodite";
import { styles } from "./LogoListStyles";

export default class Section extends Component {
  render() {
    return (
      <ul className={css(styles.logoList)}>
        <li className={css(styles.logoListItem)}>
          <a
            href="https://www.molsoncoors.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/images/logos/mcbc.svg"
              alt="Molson Coors Beverage Company logo"
              className={css(styles.logoListItemImage)}
            />
          </a>
        </li>
        <li className={css(styles.logoListItem)}>
          <a
            href="https://www.db.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/images/logos/deutsche-bank.svg"
              alt="Deutsche Bank logo"
              className={css(styles.logoListItemImage)}
            />
          </a>
        </li>
        <li className={css(styles.logoListItem)}>
          <a
            href="https://www.bp.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/images/logos/bp.svg"
              alt="bp logo"
              className={css(styles.logoListItemImage)}
            />
          </a>
        </li>
        <li className={css(styles.logoListItem)}>
          <a
            href="https://www.britishgas.co.uk"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/images/logos/british-gas.png"
              alt="British Gas logo"
              className={css(styles.logoListItemImage)}
            />
          </a>
        </li>
        <li className={css(styles.logoListItem)}>
          <a
            href="https://skybet.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/images/logos/sky-betting-and-gaming.png"
              alt="Sky Betting and Gaming logo"
              className={css(styles.logoListItemImage)}
            />
          </a>
        </li>
        <li className={css(styles.logoListItem)}>
          <a
            href="https://gendigital.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/images/logos/gen.png"
              alt="Gen logo"
              className={css(styles.logoListItemImage)}
            />
          </a>
        </li>
        <li className={css(styles.logoListItem)}>
          <a
            href="https://www.norton.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/images/logos/norton.svg"
              alt="Norton Antivirus logo"
              className={css(styles.logoListItemImage)}
            />
          </a>
        </li>
        <li className={css(styles.logoListItem)}>
          <a
            href="https://www.nordangliaeducation.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/images/logos/nord-anglia-education.png"
              alt="Nord Anglia Education logo"
              className={css(styles.logoListItemImage)}
            />
          </a>
        </li>
        {/* <li className={css([styles.logoListItem, styles.logoListItemCV])}>
          <div className={css(globalStyles.paddingBottom2x)}>
            <Paragraph size="small">For more detail...</Paragraph>
          </div>

          <Button
            onClick={() => {
              ReactGA.event({
                category: "CV",
                action: "View CV from Logos",
              });
            }}
            size="small"
            target="_blank"
            link="/Luke Taylor CV.pdf"
          >
            View CV
          </Button>
        </li> */}
      </ul>
    );
  }
}
