import React, { Component } from "react";
import { css } from "aphrodite";
import ReactGA from "react-ga";

import Header from "../components/Header";
import Hero from "../components/Hero";
import Title from "../components/Title";
import Subtitle from "../components/Subtitle";
import Section from "../components/Section";
import Footer from "../components/Footer";

import Cite from "../components/Cite";
import Title400 from "../components/Title400";
import Paragraph from "../components/Paragraph";
import CaseStudyCard from "../components/CaseStudyCard";
import { globalStyles } from "../components/GlobalStyles";
import { styles } from "../components/CaseStudyStyles";
import Link from "../components/Link";
import NDAPanel from "../components/NDAPanel";
import LargeQuote from "../components/LargeQuote";
import Callout from "../components/Callout";
import Title500 from "../components/Title500";
import Title600 from "../components/Title600";
import OutcomeCard from "../components/OutcomeCard";
import Button from "../components/Button";

export default class CaseStudyCarInsurance extends Component {
  componentDidMount() {
    document.title += " — Case Studies — Car Insurance Startup";
  }
  componentWillMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    return (
      <div>
        <Header />
        <div className={css(globalStyles.paddingBottom5x)}>
          <Hero
            sequence="&mdash;"
            title="Car Insurance. Reimagined."
            descriptions={[
              "How to disrupt a well-established industry.",
              "Preparing for capital investment.",
              "Wireframes, workshops, UI design."
            ]}
            trailingContent={
              <CaseStudyCard
                title="Collaborative Project"
                description={
                  <div className={css(globalStyles.paddingBottom2x)}>
                    To truly deliver the best possible product for the client, I worked with the
                    very experienced front-end developer and designer, Dave Hales.
                  </div>
                }
                meta={
                  <Cite
                    img="/images/davehales.jpg"
                    name="Dave Hales"
                    description={<Link styleOnly={true}>View Website</Link>}
                  />
                }
                link={"https://www.bydavehales.com/"}
                linkProps={{ target: "_blank", rel: "no-rel" }}
              />
            }
          />
        </div>

        <Section background="black">
          <div className={css(globalStyles.sectionHeader)}>
            <div className={css(globalStyles.sectionHeaderLeading)}>
              <h1>
                <Title type="onBlack" sequence={"01/"} title={"Summary"} />
              </h1>
              <h2>
                <Subtitle type="onBlack">What was delivered and how</Subtitle>
              </h2>
            </div>
            <div className={css(globalStyles.sectionHeaderTrailing)}>
              <NDAPanel />
            </div>
          </div>
          <ul className={css([styles.summaryList, globalStyles.row])}>
            <li className={css(globalStyles.columnThird)}>
              <Title400 type="onBlack">&mdash; Services</Title400>
              <ul className={css(styles.summaryListItemList)}>
                <li className={css(styles.summaryListItemListItem)}>Competitive analysis</li>
                <li className={css(styles.summaryListItemListItem)}>Workshop facilitation</li>
                <li className={css(styles.summaryListItemListItem)}>User-interface design</li>
                <li className={css(styles.summaryListItemListItem)}>Wireframing</li>
                <li className={css(styles.summaryListItemListItem)}>Preparing for investment</li>
                <li className={css(styles.summaryListItemListItem)}>Mobile app design</li>
                <li className={css(styles.summaryListItemListItem)}>Designing for iOS</li>
                <li className={css(styles.summaryListItemListItem)}>Developer collaboration</li>
              </ul>
            </li>
            <li className={css(globalStyles.columnThird)}>
              <Title400 type="onBlack">&mdash; Deliverables</Title400>
              <ul className={css(styles.summaryListItemList)}>
                <li className={css(styles.summaryListItemListItem)}>Problem defining workshop</li>
                <li className={css(styles.summaryListItemListItem)}>
                  A fully-detailed journey map
                </li>
                <li className={css(styles.summaryListItemListItem)}>Wireframes</li>
                <li className={css(styles.summaryListItemListItem)}>User-interface designs</li>
                <li className={css(styles.summaryListItemListItem)}>High-fidelity prototype</li>
              </ul>
            </li>
            <li className={css(globalStyles.columnThird)}>
              <Title400 type="onBlack">&mdash; Outcomes</Title400>
              <Paragraph size="small" type="onBlack">
                Working to a tight deadline we delivered a polished prototype which was used to demo
                to potential investors.
                <br />
                <br />
                Thanks to close developer collaboration, the prototype, and an iterative approach,
                the client produced a proof-of-concept iOS app.
              </Paragraph>
            </li>
          </ul>
        </Section>

        <Section>
          <h1>
            <Title sequence={"02/"} title={"Understanding the problem"} />
          </h1>
          <h2>
            <Subtitle>A deep understanding of the problem was the first step</Subtitle>
          </h2>

          <div className={css([globalStyles.row, globalStyles.paddingBottom5x])}>
            <div className={css([globalStyles.columnHalf])}>
              <Title400>
                <h3>Deciding which services to use</h3>
              </Title400>
              <Paragraph>
                The client knew the problem but didn’t fully know the solution. They were seasoned
                in the car insurance industry, from developers through to risk-setters and wanted to
                start fresh. The first challenge was to deeply understand the problem and work out
                how to solve it, together.
              </Paragraph>
            </div>
            <div className={css(globalStyles.columnHalf)}>
              <Title400>
                <h3>Hard deadline</h3>
              </Title400>
              <Paragraph>
                With an insurance convention in America fast approaching, the client didn’t want to
                miss a key opportunity to present their product to potential investors. This shaped
                which services to provide to the client. Being lean was key. We had to approach it
                iteratively and cut out complexity.
              </Paragraph>
            </div>
          </div>

          <div className={css(globalStyles.paddingBottom5x)}>
            <LargeQuote
              quote="The customer journey and graphical design processes were very
          straightforward for us."
              name="Paul Ridgway"
              role="Technology Leader &amp; Strategist"
            />
          </div>

          <div className={css(globalStyles.paddingBottom5x)}>
            <div className={css(globalStyles.row)}>
              <div className={css([globalStyles.columnHalf])}>
                <Title400>
                  <h3>Looking at the industry</h3>
                </Title400>
                <Paragraph>
                  The competitive analysis gave us an insight into how other car insurance companies
                  and comparison websites approach the quotation journey. The client wanted to rip
                  up the rule book and look at every question in detail. We began to identify our
                  differentiators and where others were failing.
                </Paragraph>
              </div>
              <div className={css(globalStyles.columnHalf)}>
                <Title400>
                  <h3>Getting everyone together</h3>
                </Title400>
                <Paragraph>
                  Taking learnings from the Design Sprint methodology, we ran a series of
                  mini-sessions to delve deeper into the problem. The client had an idea of how to
                  solve the problem, but we took the time to dig even deeper first. We focussed on
                  mapping out the current experience and identifying customer pain points, goals and
                  risks.
                </Paragraph>
              </div>
            </div>
          </div>

          <div className={css(globalStyles.row)}>
            <div className={css(globalStyles.columnTwoThirds)}>
              <img
                src="/images/case-studies/car-insurance/map.svg"
                alt="A whiteboard depicting 2 columns of writing and sticky post-it notes."
                className={css(globalStyles.imageFullWidth)}
              />
            </div>
            <div className={css(globalStyles.columnOneThird)}>
              <Title400>
                <h3>Starting to map</h3>
              </Title400>
              <Paragraph>
                The workshops accelerated progress on the project. Getting everyone in the room at
                the same time meant we didn’t need endless meetings or calls. Gathering technical,
                legal, risk, design, analysts, and insurance experts all together meant we were all
                on the same page from the off.
              </Paragraph>
            </div>
          </div>
        </Section>

        <Section background="grey">
          <h1>
            <Title sequence={"03/"} title={"The Solution"} />
          </h1>
          <h2>
            <Subtitle>An iterative and lean design approach</Subtitle>
          </h2>
          <div className={css([globalStyles.row, globalStyles.paddingBottom5x])}>
            <div className={css([globalStyles.columnHalf])}>
              <Title400>
                <h3>Focussing on process</h3>
              </Title400>
              <Paragraph>
                Unfortunately given the business-sensitive nature of this project I can’t share as
                specific details. The client is currently seeking investment to bring this product
                to market, and as soon as any update on that happens I’ll update this case study.
                <br />
                <br />
                The journey of acquiring car insurance was designed from the ground up, with some
                exciting new ways and I can’t wait to share them with you. It will shake up a
                well-established industry.
              </Paragraph>
            </div>
            <div className={css(globalStyles.columnHalf)}>
              <Title400>
                <h3>Being different</h3>
              </Title400>
              <Paragraph>
                We leveraged the latest in iOS capability to design an extremely streamlined and
                simplified car insurance journey. We worked with the people who set the car
                insurance quote prices to look at every question in detail.
                <br />
                <br />A quote journey right now may ask upwards of 60 questions! We wanted to start
                by asking one question and work from there until we had everything we needed. And
                then work out the best way to capture that information from the user.
              </Paragraph>
            </div>
          </div>
          <Callout>
            <Title400>
              <h3>Taking the client on a journey</h3>
            </Title400>
            <div className={css(globalStyles.paddingBottom5x)}>
              <div className={css(globalStyles.columnHalf)}>
                <Paragraph>
                  The client had a solution in mind, a flow that they already wanted to explore. At
                  first glance, it seemed extensive and simple. We dug deeper.
                </Paragraph>
              </div>
            </div>{" "}
            <img
              src="/images/case-studies/car-insurance/simple-diagram.svg"
              alt="Abstract diagram showing a simple process"
              className={css(globalStyles.imageFullWidth)}
            />
          </Callout>

          <LargeQuote
            quote="Making something look simple is easy. Making something simple to use is much harder."
            name="UK Government Design Principles"
            role={
              <Link
                target="_blank"
                rel="no-rel"
                href="https://www.gov.uk/guidance/government-design-principles"
              >
                View Principles
              </Link>
            }
          />
          <div className={css(globalStyles.paddingBottom5x)}>
            <Callout>
              <Title400>
                <h3>Reimagining the journey</h3>
              </Title400>
              <div className={css(globalStyles.paddingBottom5x)}>
                <div className={css(globalStyles.columnHalf)}>
                  <Paragraph>
                    On the face of it, the client’s journey seemed simple. We uncovered the
                    complexity behind the flow and began to expand the map. This allowed us to hide
                    this complexity from the user and provide them with a simple and easy to use
                    product.
                  </Paragraph>
                </div>
              </div>
              <img
                src="/images/case-studies/car-insurance/detailed-diagram.svg"
                alt="Abstract diagram showing a detailed process"
                className={css(globalStyles.imageFullWidth)}
              />
            </Callout>
          </div>
          <div className={css(globalStyles.paddingBottom5xUntilSmall)}>
            <div className={css(globalStyles.row)}>
              <div className={css([globalStyles.columnHalf])}>
                <Title400>
                  <h3>Map, simplify, and repeat</h3>
                </Title400>
                <Paragraph>
                  Together with the client, we spent a good proportion of time iterating over the
                  journey map. It’s super important to build a strong journey foundation before even
                  thinking about layout. We would go on to use this map throughout the whole project
                  to aid conversation, support the developers and act as a springboard to quickly
                  produce the final wireframes. To produce the map we utilised a mixture of workshop
                  sessions and remote calls.
                </Paragraph>
              </div>
              <div className={css(globalStyles.columnHalf)}>
                <Title400>
                  <h3>Working with branding</h3>
                </Title400>
                <Paragraph>
                  Given the accelerated timescales of this project, we didn’t have the luxury of
                  waiting for a fully-designed brand. We had to make some branding decisions during
                  the UI design phase knowing full well we would have to change it once the brand
                  work became ready. This is why creating solid wireframes was crucial to the
                  success of this project.
                </Paragraph>
              </div>
            </div>
          </div>
          <div className={css(globalStyles.row)}>
            <div className={css([globalStyles.columnHalf])}>
              <Title400>
                <h3>Wireframes</h3>
              </Title400>
              <Paragraph>
                Outlining all of the information required at each step allowed us to be UI design
                agnostic. We didn’t have to make strongly opinionated design decisions and could
                move quickly to build up the page templates. Working in this way also allowed us to
                be lean. We could create a wireframe and share the layout idea with the client quite
                rapidly; thus avoiding timely work associated with well-finessed UI design.
              </Paragraph>
            </div>
            <div className={css(globalStyles.columnHalf)}>
              <Title400>
                <h3>Lean</h3>
              </Title400>
              <Paragraph>
                One of the project principles was to be lean. To produce just enough of a designed
                project to prove a theory. The client wanted to build a working prototype in iOS and
                needed a prototype from us. The prototype served two purposes: one to support the
                developers, and two to act as a fall-back to demo to potential investors. We first
                thought about the happy path deeply and got this to a place we were all happy with.
                Not forgetting about the sad paths, we give these just as much attention. Users
                don’t know they’re taking a path you didn’t want them to, so the design quality
                can’t taper. Working iteratively and simplifying the design allowed us to achieve
                these goals.
              </Paragraph>
            </div>
          </div>
        </Section>

        <Section background="brandPrimary">
          <h1>
            <Title type="onBrandPrimary" sequence={"04/"} title={"Outcomes"} />
          </h1>
          <h2>
            <Subtitle type="onBrandPrimary">An iterative and lean design approach</Subtitle>
          </h2>

          <div className={css(globalStyles.paddingBottom5xUntilSmall)}>
            <div className={css(globalStyles.row)}>
              <div className={css(globalStyles.columnHalf)}>
                <OutcomeCard
                  title={<Title400 spacing="none">Journey map</Title400>}
                  img="/images/case-studies/car-insurance/outcome-map.svg"
                  subtitle={<Title500>Conversation piece</Title500>}
                  description={
                    <Paragraph size="small">
                      Most discussions in the initial phase of the project focussed around this map.
                      It was used to design the user-interface and produce the prototype. The
                      developers used this map to create their proof-of-concept iOS app.
                    </Paragraph>
                  }
                />
              </div>
              <div className={css(globalStyles.columnHalf)}>
                <OutcomeCard
                  title={<Title400 spacing="none">Workshops</Title400>}
                  img="/images/case-studies/car-insurance/outcome-workshop.svg"
                  subtitle={<Title500>All together now</Title500>}
                  description={
                    <Paragraph size="small">
                      I’ve written here about how important it was to have everyone in the room at
                      the same time. It kept the decision loop short, and more importantly quick.
                      Not only this, but it gave us a chance to all get energised and start to get
                      excited about solving the problem ahead!
                    </Paragraph>
                  }
                />
              </div>
            </div>
          </div>
          <div className={css(globalStyles.row)}>
            <div className={css(globalStyles.columnHalf)}>
              <OutcomeCard
                title={<Title400 spacing="none">Prototype</Title400>}
                img="/images/case-studies/car-insurance/outcome-prototype.svg"
                subtitle={<Title500>Tap-through designs, as close to real</Title500>}
                description={
                  <Paragraph size="small">
                    The prototype was delivered under time pressure to be able to pitch the product
                    to investors. It was also used for the developers to base their proof-of-concept
                    iOS app from.
                  </Paragraph>
                }
                button={<Button>View Prototype</Button>}
                meta={<NDAPanel type="small" />}
              />
            </div>
            <div className={css(globalStyles.columnHalf)}>
              <OutcomeCard
                title={<Title400 spacing="none">iOS App</Title400>}
                img="/images/case-studies/car-insurance/outcome-ios.svg"
                subtitle={<Title500>Leveraging the latest iOS technology</Title500>}
                description={
                  <Paragraph size="small">
                    Their developers used the prototype we’d designed to create a proof-of-concept
                    iOS app. They are currently seeking investment for the product.
                  </Paragraph>
                }
                button={<Button>View App</Button>}
                meta={<NDAPanel type="small" />}
              />
            </div>
          </div>
        </Section>

        <Section>
          <h1>
            <Title sequence={"05/"} title={"More Case Studies"} />
          </h1>
          <h2>
            <Subtitle>Recent client projects</Subtitle>
          </h2>
          <div className={css(globalStyles.row)}>
            <div className={css(globalStyles.columnOneThird)}>
              <Title400>This Case Study</Title400>
              <Title600>Car Insurance Reimagined</Title600>
              <div className={css(globalStyles.paddingBottom2x)}>
                <Paragraph size="small">
                  How a startup wanted to disrupt the well-established car insurance industry
                </Paragraph>
              </div>
              <Button size="small" link="#">
                Back to Top ↑
              </Button>
            </div>
            <div className={css(globalStyles.columnOneThird)}>
              <Title400>Next Case Study</Title400>
              <Title600>Gambling Age Visibility</Title600>
              <div className={css(globalStyles.paddingBottom2x)}>
                <Paragraph size="small">
                  Being responsible, by improving age restriction visibility on a gambling product.
                </Paragraph>
              </div>
              <Button size="small" link="/case-studies/age-visibility/">
                Read Next Case Study →
              </Button>
            </div>
          </div>
        </Section>

        <Footer />
      </div>
    );
  }
}
